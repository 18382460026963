import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authorizationFeatureKey, AuthorizationState } from './authorization.state';

export const selectFeature = createFeatureSelector<AuthorizationState>(authorizationFeatureKey);

export const selectCompanyDto = createSelector(selectFeature, (state: AuthorizationState) => state.companyDto);

export const isUserTokenPresent = createSelector(selectFeature, (state: AuthorizationState) => !!state.token);

export const isAuthorizationLoading = createSelector(selectFeature, (state: AuthorizationState) => state.loading);

export const selectJwtToken = createSelector(selectFeature, (state: AuthorizationState) => state?.token);
export const selectJwtRefreshToken = createSelector(selectFeature, (state: AuthorizationState) => state?.refreshToken);

export const selectTenantIdentifier = createSelector(selectFeature, (state: AuthorizationState) =>
  state?.companyDto ? `${state.companyDto.nipt}|${state.companyDto.year}|${state.companyDto.businessCode}|${state.companyDto.isDemo}` : undefined,
);

export const selectCompanies = createSelector(selectFeature, (state: AuthorizationState) => state.companyList);

export const selectUserContracts = createSelector(selectFeature, (state: AuthorizationState) => state.userContracts);

export const isSecondStepSuccessful = createSelector(selectFeature, (state: AuthorizationState) => !!state.token);

export const selectUserModules = createSelector(selectFeature, (state: AuthorizationState) =>
  !state.isLoadingModulesFunctionsAndPermissions ? state.modules : null,
);

export const selectUserFunctions = createSelector(selectFeature, (state: AuthorizationState) =>
  !state.isLoadingModulesFunctionsAndPermissions ? state.functions : null,
);

export const selectUserPermissions = createSelector(selectFeature, (state: AuthorizationState) =>
  !state.isLoadingModulesFunctionsAndPermissions ? state.permissions : null,
);

export const selectUserConfigurations = createSelector(selectFeature, (state: AuthorizationState) =>
  !state.isLoadingModulesFunctionsAndPermissions ? state.configurations : null,
);

export const selectIsLoadingModulesFunctionsAndPermissions = createSelector(
  selectFeature,
  (state: AuthorizationState) => state.isLoadingModulesFunctionsAndPermissions,
);
export const selectLoggedUserName = createSelector(selectFeature, (state: AuthorizationState) => state.loggedUserName);
export const selectAuthSocialHasError = createSelector(selectFeature, (state: AuthorizationState) => state.socialStep1HasError);

export const selectAuthStep1HasError = createSelector(selectFeature, (state: AuthorizationState) => state.step1HasError);

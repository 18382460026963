import { Injectable } from '@angular/core';
import { HttpClientPublic } from '../nswag.api';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DevPosUrlInitialize {
  devPosInternalUrl!: string;

  constructor(private http: HttpClientPublic) {}

  async loadConfig() {
    const response = await firstValueFrom(this.http.getDevPosInternal());
    this.devPosInternalUrl = response?.data!;
    return this.devPosInternalUrl;
  }

  getConfig() {
    return this.devPosInternalUrl;
  }
}

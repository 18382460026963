import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// fetch(`${environment.baseUrl}/api/Public/angular-dsn`).then(r =>
//   r
//     .json()
//     .then((dsn: { data?: string; succeeded: boolean; message?: string }) => {
//       if (!dsn.succeeded) return 'https://xxx@sentry.kontabiliteti.online/x';
//       if (!dsn.data) return 'https://xxx@sentry.kontabiliteti.online/x';
//       return dsn.data;
//     })
//     .catch(() => 'https://xxx@sentry.kontabiliteti.online/x')
//     .then(dsn => {
//       Sentry.init({
//         dsn: dsn,
//         release: `${packageInfo.name}@${packageInfo.version}`,
//
//         beforeSend(event) {
//           const lsKey = 'sentryEnabled';
//           const lastLoginUserKey = 'lastLoggedInUser';
//           const stateRootKey = '__root';
//
//           if (!localStorage.getItem(lsKey))
//             localStorage.setItem(lsKey, environment.production ? '1' : '0');
//
//           const isSentryEnabled = localStorage.getItem(lsKey) == '1';
//           console.log('isSentryEnabled', isSentryEnabled);
//           if (!isSentryEnabled) return null;
//
//           event.tags = event.tags || {};
//
//           function extractContractUserName() {
//             let tags = {};
//             try {
//               const lastLoginUser = localStorage.getItem(lastLoginUserKey);
//               if (!lastLoginUserKey) return tags;
//               const lastLoginUserJson = JSON.parse(lastLoginUser!);
//               if (lastLoginUserJson?.contract)
//                 tags['contract'] = lastLoginUserJson?.contract;
//               if (lastLoginUserJson?.username)
//                 tags['username'] = lastLoginUserJson?.username;
//             } catch (e) {
//               console.error('Failed to parse lastLoggedInUser', e);
//             }
//             return tags;
//           }
//
//           function extractTenant() {
//             let tags = {};
//             try {
//               const stateRoot = localStorage.getItem(stateRootKey);
//               if (!stateRoot) return tags;
//               const stateRootJson = JSON.parse(stateRoot);
//               if (stateRootJson?.authorization?.companyDto) {
//                 const comp = stateRootJson.authorization!.companyDto;
//                 if (comp?.nipt) tags['tenant.nipt'] = comp.nipt;
//                 if (comp?.year) tags['tenant.year'] = comp.year;
//                 if (comp?.businessCode)
//                   tags['tenant.businessCode'] = comp.businessCode;
//                 if (comp?.isDemo) tags['tenant.isDemo'] = comp.isDemo;
//               }
//             } catch (e) {
//               console.error('Failed to parse lastLoggedInUser', e);
//             }
//             return tags;
//           }
//
//           const cu = extractContractUserName();
//           if (cu) event.tags = { ...event.tags, ...cu };
//
//           const tnt = extractTenant();
//           if (tnt) event.tags = { ...event.tags, ...tnt };
//
//           return event;
//         },
//       });
//     })
// );

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.debug('Bootstrap success'))
  .catch(err => console.error(err));

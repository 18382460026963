import { createAction, props } from '@ngrx/store';
import { CompanyStructureDto, CostingCenterDto, CurrencyDto } from 'src/app/shared/nswag.api';

export const activeCurrenciesRequest = createAction('[Set] activeCurrencies request');
export const activeCostingCentersRequest = createAction('[Set] activeCostingCenters request');
export const onAddCurrency = createAction('[activeCurrencies] AddCurrency');
export const onDeleteCurrency = createAction('[activeCurrencies] DeleteCurrency');
export const activeCurrenciesSuccess = createAction('[Set] activeCurrencies success', props<{ activeCurrencies?: CurrencyDto[] }>());
export const activeCostingCentersSuccess = createAction('[Set] activeCostingCenters success', props<{ costingCenter?: CostingCenterDto[] }>());
export const activeCurrenciesFailure = createAction(
  '[Set] activeCurrencies fail',
  props<{
    error: string;
  }>(),
);
export const activeCompanyStructuresRequest = createAction('[Set] activeCompanyStructures request');

export const activeCompanyStructuresSuccess = createAction(
  '[Set] activeCostingCenters success',
  props<{ companyStructure?: CompanyStructureDto[] }>(),
);
export const activeCostingCentersFailure = createAction(
  '[Set] CostingCenters fail',
  props<{
    error: string;
  }>(),
);

export const activecompanyStructuresFailure = createAction(
  '[Set] companyStructure fail',
  props<{
    error: string;
  }>(),
);

export const skipCacheRequest = createAction('[Set] Skip Cache Change', props<{ skipCache: boolean; showToaster: boolean }>());

export const ClearConfigurationCache = createAction('[Configuration] Clear Configuration Cache');

export const companyOptionsChange = createAction('companyOptionsChange');

/* eslint-disable @typescript-eslint/no-explicit-any */
import 'hammerjs';

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, isDevMode, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtInterceptor } from 'src/app/authorization/interceptors/jwt-interceptor';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { TenantInterceptor } from './authorization/interceptors/tenant-interceptor';
import { rootReducers } from './state/store.index';
import { CommonModule } from '@angular/common';
import { storageMetaReducer } from './state/meta-reducers.index';
import { authorizationFeatureKey, authorizationPersistKeys } from './authorization/store/authorization.state';
import { dashboardFeatureKey, dashboardPersistKeys } from './dashboard/store/dashboard.state';
import { API_BASE_URL } from './shared/nswag.api';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationEffects } from './authorization/store/authorization.effects';
import { loadModulesFunctionsPermissions } from './authorization/store/authorization.actions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { isUserTokenPresent } from './authorization/store/authorization.selectors';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { hrFeatureKey, hrPersistKeys } from './human-resources/store/hr.state';
import { configurationsFeatureKey, configurationsPersistKeys } from './configurations/store/configurations.state';
import { ConfigurationsEffects } from './configurations/store/configurations.effects';
import { usersFeatureKey, usersPersistKeys } from './configurations/modules/users/store/user.state';
import { itemsFeatureKey, itemsPersistKeys } from './inventory/modules/items/store/items.state';
import { inventoryFeatureKey, inventoryPersistKeys } from './inventory/store/inventory.state';
import { ChangePasswordComponentComponent } from './authorization/pages/change-password-component/change-password-component.component';
import { NavigationService } from './shared/services/navigation-service.service';
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { CacheInterceptor } from './shared/interceptor/cache.interceptor';
import { LanguageInterceptor } from './shared/interceptor/language.interceptor';
import { ThrowInterceptor } from './shared/interceptor/throw.interceptor';
import { ErrorInterceptor } from 'src/error.interceptor';
import { LoadingModule } from './loading/loading.module';
import { provideToastr } from 'ngx-toastr';

import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { SignalrService } from './admin-layout/services/signalr.service';
import { SignalRUrlInitialize } from './shared/services/signalr-url-initializer.service';
import { SIGNAL_BASE_URL } from './shared/signalr.api';
import { SignalrInterceptor } from './authorization/interceptors/signalr-interceptor';
import { DevPosUrlInitialize } from './shared/services/devpos-url-initializer.service';
import { DEVPOS_BASE_URL } from './shared/devpos.api';
import { LoggedInGuard } from './authorization/guards/logged-in.guard';
import { ServiceWorkerModule } from '@angular/service-worker';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./authorization/authorization.module').then(m => m.AuthorizationModule),
  },
  {
    path: 'change-password',
    component: ChangePasswordComponentComponent,
  },
  {
    path: '',
    canActivateChild: [LoggedInGuard],
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

function appInitializer(svc: SignalRUrlInitialize, devPosSvc: DevPosUrlInitialize) {
  return () => svc.loadConfig().then(() => devPosSvc.loadConfig());
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    AngularSvgIconModule.forRoot(),
    // translate
    TranslateModule.forRoot({
      defaultLanguage: 'sq',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // ngrx
    StoreModule.forRoot(rootReducers, {
      metaReducers: [
        storageMetaReducer<any>(
          [
            ...authorizationPersistKeys,
            ...configurationsPersistKeys,
            ...dashboardPersistKeys,
            ...hrPersistKeys,
            ...inventoryPersistKeys,
            ...itemsPersistKeys,
            ...usersPersistKeys,
            ...authorizationFeatureKey,
            ...configurationsFeatureKey,
            ...dashboardFeatureKey,
            ...hrFeatureKey,
            ...inventoryFeatureKey,
            ...itemsFeatureKey,
            ...usersFeatureKey,
          ],
          'root',
        ),
      ],
    }),
    EffectsModule.forRoot([AuthorizationEffects, ConfigurationsEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    // ng-select
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    LoadingModule,
    NgxTippyModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    SignalrService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [SignalRUrlInitialize, DevPosUrlInitialize],
      multi: true,
    },
    {
      provide: SIGNAL_BASE_URL,
      useFactory: (svc: SignalRUrlInitialize) => svc.getConfig(),
      deps: [SignalRUrlInitialize],
      multi: false,
    },
    {
      provide: DEVPOS_BASE_URL,
      useFactory: (svc: DevPosUrlInitialize) => svc.getConfig(),
      deps: [DevPosUrlInitialize],
      multi: false,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ThrowInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SignalrInterceptor, multi: true },
    {
      provide: API_BASE_URL,
      useValue: environment.baseUrl,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: <SocialAuthServiceConfig>{
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.google.key, {
              oneTapEnabled: false,
            }),
          },
        ],
      },
    },
    NavigationService,
    provideAnimations(),
    provideToastr({
      autoDismiss: true,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
      countDuplicates: true,
      extendedTimeOut: 1000,
      resetTimeoutOnDuplicate: true,
      tapToDismiss: false,
      maxOpened: 5,
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      },
      progressBar: true,
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(
    private store: Store, // private ngSelectConfig: NgSelectConfig, // private translate: TranslateService
    private translateSvc: TranslateService,
  ) {
    this.translateSvc.use(this.translateSvc.getDefaultLang());
    this.store
      .select(isUserTokenPresent)
      .pipe(
        distinctUntilChanged(),
        debounceTime(100),
        filter(isUserTokenPresent => isUserTokenPresent),
        tap(() => this.store.dispatch(loadModulesFunctionsPermissions({ skipCache: false }))),
      )
      .subscribe();
  }
}

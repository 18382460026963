import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectCompanyDto } from '../store/authorization.selectors';
import { IsAssetUrl, IsSignalrUrl } from '../services/auth.util';
import { SignalRUrlInitialize } from 'src/app/shared/services/signalr-url-initializer.service';

@Injectable()
export class SignalrInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private signalRUrlInitialize: SignalRUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isAssetUrl = IsAssetUrl(request);
    if (isAssetUrl) return next.handle(request);

    const isSignalrUrl = IsSignalrUrl(request, this.signalRUrlInitialize.signalrUrl);
    if (!isSignalrUrl) return next.handle(request);

    const username = localStorage.getItem('attemptedUsername');
    if (!username) return next.handle(request);

    return this.store.select(selectCompanyDto).pipe(
      take(1),
      map(companyDto => companyDto?.contractId),
      tap(contractId => {
        if (!contractId) return;
        request = request.clone({
          setHeaders: {
            'X-Contract-Id': contractId,
            'X-Username': username,
          },
        });
      }),
      switchMap(() => next.handle(request)),
    );
  }
}

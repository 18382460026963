import { Injectable } from '@angular/core';
import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { IsApiUrl, IsAssetUrl, IsDevPosUrl } from '../../authorization/services/auth.util';
import { switchMap, take } from 'rxjs';
import { DevPosUrlInitialize } from '../services/devpos-url-initializer.service';
import { selectSkipCache } from 'src/app/inventory/store/inventory.selectors';

export const IGNORE_REDIS_CACHE = new HttpContextToken<boolean>(() => false);

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private devPosUrlInitialize: DevPosUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    let skipCache = request.context.get(IGNORE_REDIS_CACHE);
    if (skipCache) {
      if (!this.shouldIntercept(request)) return next.handle(request);
      return next.handle(
        request.clone({
          setHeaders: {
            'x-ignore-cache': 'true',
          },
        }),
      );
    }

    if (!this.shouldIntercept(request)) return next.handle(request);

    return this.store.select(selectSkipCache(moment())).pipe(
      take(1),
      switchMap(skipCache => {
        return !skipCache
          ? next.handle(request.clone({}))
          : next.handle(
              request.clone({
                setHeaders: {
                  'x-ignore-cache': skipCache ? 'true' : 'false',
                },
              }),
            );
      }),
    );
  }

  private shouldIntercept(request: HttpRequest<unknown>) {
    return !IsAssetUrl(request) ? IsApiUrl(request) || IsDevPosUrl(request, this.devPosUrlInitialize.devPosInternalUrl) : false;
  }
}

import { ActionReducerMap } from '@ngrx/store';
import { authorizationReducer } from '../authorization/store/authorization.reducer';
import { authorizationFeatureKey, AuthorizationState } from '../authorization/store/authorization.state';
import { configurationsReducer } from '../configurations/store/configurations.reducer';
import { configurationsFeatureKey, ConfigurationsState } from '../configurations/store/configurations.state';
import { dashboardReducer } from '../dashboard/store/dashboard.reducer';
import { dashboardFeatureKey, DashboardState } from '../dashboard/store/dashboard.state';
import { inventoryFeatureKey, InventoryState } from '../inventory/store/inventory.state';
import { inventoryReducer } from '../inventory/store/inventory.reducer';

export interface RootState {
  [authorizationFeatureKey]: AuthorizationState;
  [dashboardFeatureKey]: DashboardState;
  [configurationsFeatureKey]: ConfigurationsState;
  [inventoryFeatureKey]: InventoryState;
}

export const rootReducers: ActionReducerMap<RootState> = {
  [authorizationFeatureKey]: authorizationReducer,
  [dashboardFeatureKey]: dashboardReducer,
  [configurationsFeatureKey]: configurationsReducer,
  [inventoryFeatureKey]: inventoryReducer,
};

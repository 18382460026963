import { createFeatureSelector, createSelector } from '@ngrx/store';
import { inventoryFeatureKey, InventoryState } from './inventory.state';
import { GetStateData } from '../../shared/interfaces/state/state';
import { Moment } from 'moment';
import { EXPIRE_API_SECONDS } from '../../shared/constants/api-priority.seconds';
import { configurationsFeatureKey, ConfigurationsState } from '../../configurations/store/configurations.state';

export const selectFeature = createFeatureSelector<InventoryState>(inventoryFeatureKey);

export const selectConfFeature = createFeatureSelector<ConfigurationsState>(configurationsFeatureKey);
export const selectSkipCache = (now: Moment) =>
  createSelector(selectConfFeature, config => GetStateData(config.skipCache, now, EXPIRE_API_SECONDS.SKIP_CACHE, false));

export const selectItemAccSchemes = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    state.cachedSchemes.map(data => GetStateData(data, now, EXPIRE_API_SECONDS.ITEM_SCHEMES, skipCache)),
  );

export const selectItemGroups = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    (state.cachedGroups ?? []).map(data => GetStateData(data, now, EXPIRE_API_SECONDS.ITEM_GROUP, skipCache)),
  );

export const selectItemCategories = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    (state.cachedCategories ?? []).map(data => GetStateData(data, now, EXPIRE_API_SECONDS.ITEM_CATEGORY, skipCache)),
  );

export const selectAccountingSchemes = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    (state.cachedGlSchemes ?? []).map(data => GetStateData(data, now, EXPIRE_API_SECONDS.ITEM_SCHEMES, skipCache)),
  );

export const selectAssetCategories = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    (state.cachedAssetCategories ?? []).map(data => GetStateData(data, now, EXPIRE_API_SECONDS.ITEM_SCHEMES, skipCache)),
  );

export const selectAssetGroup = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    (state.cachedAssetsGroups ?? []).map(data => GetStateData(data, now, EXPIRE_API_SECONDS.ITEM_SCHEMES, skipCache)),
  );

export const selectGlAccounts = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    state.cachedGlAccs.map(loc => GetStateData(loc, now, EXPIRE_API_SECONDS.GL_ACCOUNTS, skipCache)),
  );

export const selectUnits = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    state.cachedUnits.map(data => GetStateData(data, now, EXPIRE_API_SECONDS.UNIT_OF_MEASURES, skipCache)),
  );

export const selectVats = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    state.cachedVats.map(data => GetStateData(data, now, EXPIRE_API_SECONDS.VATS, skipCache)),
  );

export const selectParentCategories = (now: Moment) =>
  createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
    state.cachedParentCategory.map(data => GetStateData(data, now, EXPIRE_API_SECONDS.PARENTCATEGORY, skipCache)),
  );

import { Action, ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { merge, pick } from 'lodash';

export function storageMetaReducer<S, A extends Action = Action>(saveKeys: string[], localStorageKey: string) {
  return function (reducer: ActionReducer<S, A>) {
    return function (state: S, action: A): S {
      const nextState = reducer(state, action);
      // init the application state.
      if (action.type === INIT || action.type === UPDATE) {
        const storageValue = localStorage.getItem(`__${localStorageKey}`);
        if (storageValue) {
          try {
            const savedState = JSON.parse(storageValue);
            return merge(nextState, savedState);
          } catch {
            localStorage.removeItem(`__${localStorageKey}`);
          }
        }
      }

      try {
        const stateToSave = pick(nextState, saveKeys);
        localStorage.setItem(`__${localStorageKey}`, JSON.stringify(stateToSave));
      } catch (e) {
        console.error('Error saving to localStorage', e);
      }

      // save the next state to the application storage.
      return nextState;
    };
  };
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectTenantIdentifier } from '../store/authorization.selectors';
import { IsApiUrl, IsAssetUrl, IsDevPosUrl, IsSignalrUrl } from '../services/auth.util';
import { SignalRUrlInitialize } from 'src/app/shared/services/signalr-url-initializer.service';
import { DevPosUrlInitialize } from '../../shared/services/devpos-url-initializer.service';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private signalRUrlInitialize: SignalRUrlInitialize,
    private devPosUrlInitialize: DevPosUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isAssetUrl = IsAssetUrl(request);
    if (isAssetUrl) return next.handle(request);

    const isApiUrl = IsApiUrl(request);
    const isSignalrUrl = IsSignalrUrl(request, this.signalRUrlInitialize.signalrUrl);
    const isDevPosUrl = IsDevPosUrl(request, this.devPosUrlInitialize.devPosInternalUrl);
    if (!isApiUrl && !isSignalrUrl && !isDevPosUrl) return next.handle(request);

    return this.store.select(selectTenantIdentifier).pipe(
      take(1),
      map(tenantIdentifier => {
        if (!tenantIdentifier) return;
        request = request.clone({
          setHeaders: {
            'X-Tenant-Id': tenantIdentifier,
          },
        });
      }),
      switchMap(() => next.handle(request)),
    );
  }
}

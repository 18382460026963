import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { IsApiUrl, IsAssetUrl, IsDevPosUrl } from '../../authorization/services/auth.util';
import { switchMap, take } from 'rxjs';
import { selectLanguage } from 'src/app/dashboard/store/dashboard.selectors';
import { DevPosUrlInitialize } from '../services/devpos-url-initializer.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private devPosUrlInitialize: DevPosUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const isAssetUrl = IsAssetUrl(request);
    if (isAssetUrl) return next.handle(request);

    const isApiUrl = IsApiUrl(request);
    const isDevPosUrl = IsDevPosUrl(request, this.devPosUrlInitialize.devPosInternalUrl);
    if (!isApiUrl && !isDevPosUrl) return next.handle(request);

    return this.store.select(selectLanguage).pipe(
      take(1),
      switchMap(language =>
        next.handle(
          request.clone({
            setHeaders: {
              'Accept-Language': language === 'sq' ? 'sq-AL' : 'en',
            },
          }),
        ),
      ),
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { IsApiUrl, IsAssetUrl, IsDevPosUrl, IsSignalrUrl } from '../../authorization/services/auth.util';
import { SignalRUrlInitialize } from 'src/app/shared/services/signalr-url-initializer.service';
import { DevPosUrlInitialize } from '../services/devpos-url-initializer.service';

export const DONT_THROW_EXCEPTION = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ThrowInterceptor implements HttpInterceptor {
  constructor(
    private signalRUrlInitialize: SignalRUrlInitialize,
    private devPosUrlInitialize: DevPosUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const isAssetUrl = IsAssetUrl(request);
    if (isAssetUrl) return next.handle(request);

    const isApiUrl = IsApiUrl(request);
    const isSignalrUrl = IsSignalrUrl(request, this.signalRUrlInitialize.signalrUrl);
    const isDevPosUrl = IsDevPosUrl(request, this.devPosUrlInitialize.devPosInternalUrl);
    if (!isApiUrl && !isSignalrUrl && !isDevPosUrl) return next.handle(request);

    if (!request.context.get(DONT_THROW_EXCEPTION)) return next.handle(request);

    return next.handle(
      request.clone({
        setHeaders: {
          'x-dont-throw-exception': 'true',
        },
      }),
    );
  }
}

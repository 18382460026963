import { CompanyDto, FunctionEnum, ModuleEnum, PermissionsEnum, UserContractDto, CompanyOptionEnum } from '../../shared/nswag.api';

export const authorizationFeatureKey = 'authorization';

export const authorizationPersistKeys = ['companyDto', 'token', 'refreshToken', 'companyList', 'loggedUserName'].map(
  value => `${authorizationFeatureKey}.${value}`,
);

export interface AuthorizationState {
  companyList: CompanyDto[] | undefined;
  userContracts: UserContractDto[];
  token: string | undefined;
  refreshToken: string | undefined;
  companyDto?: CompanyDto;
  loading: boolean;
  error: string | undefined;
  modules: ModuleEnum[];
  functions: FunctionEnum[];
  permissions: PermissionsEnum[];
  configurations: { [key in keyof typeof CompanyOptionEnum]?: boolean };
  isLoadingModulesFunctionsAndPermissions: boolean;
  loggedUserName: string | undefined;
  socialStep1HasError: boolean;
  step1HasError: boolean;
}

export const initialAuthorizationState: AuthorizationState = {
  companyList: undefined,
  userContracts: [],
  token: undefined,
  refreshToken: undefined,
  companyDto: undefined,
  loading: false,
  error: undefined,
  modules: [],
  functions: [],
  permissions: [],
  configurations: [],
  isLoadingModulesFunctionsAndPermissions: true,
  loggedUserName: undefined,
  socialStep1HasError: false,
  step1HasError: false,
};

import { createAction, props } from '@ngrx/store';
import { CountryDto, DynamicFilter, DynamicOrder, DynamicPage, PagedResultOfRoleDto, RoleDto, UserDto } from 'src/app/shared/nswag.api';

export const getAllRolesRequest = createAction('[Users] GetAllRoles Request');
export const getAllRolesSuccess = createAction(
  '[Users] GetAllRoles Success',
  props<{
    roleDto: RoleDto[];
  }>(),
);
export const getAllRolesFailure = createAction(
  '[Users] GetAllRoles Failure',
  props<{
    error: string;
  }>(),
);

export const getAllRolesPaginated = createAction(
  '[Users] GetAllRolesPaginated',
  props<{
    dynamicPage?: DynamicPage;
    isOrOperator: boolean;
    filters: DynamicFilter[];
    orders: DynamicOrder[];
  }>(),
);
export const getAllRolesPaginatedSuccess = createAction(
  '[Users] GetAllRolesPaginated Success',
  props<{
    roleResult: PagedResultOfRoleDto;
  }>(),
);
export const getAllRolesPaginatedFailure = createAction(
  '[Users] GetAllRolesPaginated Failure',
  props<{
    error: string;
  }>(),
);

export const signupRequest = createAction(
  '[Users] Signup Request',
  props<{
    userDto: UserDto;
  }>(),
);
export const signupSuccess = createAction(
  '[Users] Signup Success',
  props<{
    success: boolean;
  }>(),
);
export const signupFailure = createAction(
  '[Users] Signup Failure',
  props<{
    error: string;
  }>(),
);

export const getAllCountriesRequest = createAction('[Users] GetAllCountries Request');
export const getAllCountriesSuccess = createAction(
  '[Users] GetAllCountries Success',
  props<{
    countries: CountryDto[];
  }>(),
);
export const getAllCountriesFailure = createAction(
  '[Users] GetAllCountries Failure',
  props<{
    error: string;
  }>(),
);

export const editUserRequest = createAction(
  '[Users] EditUser Request',
  props<{
    userDto: UserDto;
  }>(),
);
export const editUserSuccess = createAction(
  '[Users] EditUser Success',
  props<{
    user: UserDto;
  }>(),
);
export const editUserFailure = createAction(
  '[Users] EditUser Failure',
  props<{
    error: string;
  }>(),
);
export const editLoggedInUserRequest = createAction(
  '[Users] editLoggedInUser Request',
  props<{
    userDto: UserDto;
  }>(),
);
export const editLoggedInUserSuccess = createAction(
  '[Users] editLoggedInUser Success',
  props<{
    user: UserDto;
  }>(),
);
export const editLoggedInUserFailure = createAction(
  '[Users] editLoggedInUserFailure Failure',
  props<{
    error: string;
  }>(),
);
export const editUserStarted = createAction('[Users] EditUser Started');

export const editRoleRequest = createAction(
  '[Users] EditRole Request',
  props<{
    roleDto: RoleDto;
  }>(),
);
export const editRoleSuccess = createAction(
  '[Users] EditRole Success',
  props<{
    role: RoleDto;
  }>(),
);
export const editRoleFailure = createAction(
  '[Users] EditRole Failure',
  props<{
    error: string;
  }>(),
);

export const addRoleRequest = createAction(
  '[Users] AddRole Request',
  props<{
    roleDto: RoleDto;
  }>(),
);
export const addRoleSuccess = createAction(
  '[Users] AddRole Success',
  props<{
    role: RoleDto;
  }>(),
);
export const addRoleFailure = createAction(
  '[Users] AddRole Failure',
  props<{
    error: string;
  }>(),
);

// Clear Cache Actions
export const ClearRolesCache = createAction('[Users] Clear Roles Cache');
export const ClearCountriesCache = createAction('[Users] Clear Countries Cache');
export const ClearRoleResultCache = createAction('[Users] Clear RoleResult Cache');
export const ClearUsersCache = createAction('[Users] Clear Users Cache');

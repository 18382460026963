import { HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export interface LastUserInStorage {
  contract: string;
  username: string;
}

export function GetLastLoggedInUser(): LastUserInStorage | undefined {
  const obj = localStorage.getItem('lastLoggedInUser');
  if (!obj) return undefined;

  try {
    const lastUser = JSON.parse(obj);
    if (!lastUser) return undefined;
    if (lastUser.contract && lastUser.username) return lastUser;
    return undefined;
  } catch (error) {
    return undefined;
  }
}

export function SetLastLoggedInUser(contract: string | null, username: string | null): void {
  const lastUser: LastUserInStorage = {
    contract: contract ?? '',
    username: username ?? '',
  };
  localStorage.setItem('lastLoggedInUser', JSON.stringify(lastUser));
}

export function ClearLastLoggedInUser(): void {
  localStorage.removeItem('lastLoggedInUser');
}

export function IsApiUrl(req: HttpRequest<unknown>) {
  // It's an absolute url with the same origin.
  if (req.url.startsWith(`${environment.baseUrl}/`)) {
    return true;
  }

  // It's a relative url like /api/Products
  // eslint-disable-next-line no-useless-escape
  if (/^\/[^\/].*/.test(req.url)) {
    return true;
  }

  // It's an absolute or protocol relative url that
  // doesn't have the same origin.
  return false;
}

export function IsSignalrUrl(req: HttpRequest<unknown>, signalrUrl?: string) {
  return !!(signalrUrl && req.url.startsWith(signalrUrl));
}

export function IsDevPosUrl(req: HttpRequest<unknown>, devPosUrl?: string) {
  return !!(devPosUrl && req.url.startsWith(devPosUrl));
}

export function IsAssetUrl(req: HttpRequest<unknown>) {
  return req.url.startsWith('assets/');
}

import { createReducer, on } from '@ngrx/store';
import {
  ClearConfigurationCache,
  activeCompanyStructuresRequest,
  activeCompanyStructuresSuccess,
  activeCostingCentersRequest,
  activeCostingCentersSuccess,
  activeCurrenciesFailure,
  activeCurrenciesRequest,
  activeCurrenciesSuccess,
  companyOptionsChange,
  onAddCurrency,
  onDeleteCurrency,
  skipCacheRequest,
} from './configurations.actions';
import { initialConfigurationsState } from './configurations.state';
import * as moment from 'moment';
import { IStateData } from 'src/app/shared/interfaces/state/state';
import { CompanyStructureDto, CostingCenterDto } from 'src/app/shared/nswag.api';

// reduceri
export const configurationsReducer = createReducer(
  initialConfigurationsState,

  on(activeCurrenciesRequest, state => ({
    ...state,
    activeCurrencies: undefined,
  })),
  on(activeCurrenciesRequest, state => ({
    ...state,
    activeCurrencies: undefined,
  })),
  on(onAddCurrency, state => ({
    ...state,
    activeCurrencies: undefined,
  })),
  on(activeCurrenciesSuccess, (state, { activeCurrencies }) => ({
    ...state,
    activeCurrencies: {
      data: activeCurrencies,
      lastUpdatedDate: moment(),
    },
  })),
  on(activeCurrenciesFailure, state => ({
    ...state,
  })),
  on(onDeleteCurrency, state => ({
    ...state,
    activeCurrencies: undefined,
  })),
  on(skipCacheRequest, (state, { skipCache }) => ({
    ...state,
    skipCache: {
      data: skipCache,
      lastUpdatedDate: moment(),
    },
  })),
  on(activeCostingCentersRequest, state => ({
    ...state,
    cachedCostingCenters: [],
  })),
  on(activeCostingCentersSuccess, (state, { costingCenter }) => {
    return {
      ...state,
      cachedCostingCenters:
        costingCenter?.map(el => {
          return <IStateData<CostingCenterDto>>{
            data: el,
            lastUpdatedDate: moment(),
          };
        }) ?? [],
    };
  }),
  on(activeCompanyStructuresRequest, state => ({
    ...state,
    cachedCompanyStructures: [],
  })),
  on(activeCompanyStructuresSuccess, (state, { companyStructure }) => {
    return {
      ...state,
      cachedCompanyStructures:
        companyStructure?.map(el => {
          return <IStateData<CompanyStructureDto>>{
            data: el,
            lastUpdatedDate: moment(),
          };
        }) ?? [],
    };
  }),
  on(ClearConfigurationCache, state => ({
    ...state,
    activeCurrencies: {
      data: undefined,
      lastUpdatedDate: undefined,
    },
    cachedUsers: [],
    cachedCostingCenters: [],
    decimalPlaces: undefined,
  })),
  on(companyOptionsChange, state => ({
    ...state,
    decimalPlaces: undefined,
  })),
);

<ngx-loading [show]="(isLoading$ | async)!"></ngx-loading>

<div
  class="flex min-h-[100vh] items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-fixed bg-center bg-cover bg-no-repeat"
  style="
    background-image: linear-gradient(rgba(135, 80, 156, 0.9), rgba(135, 80, 156, 0.9)),
      url(https://dev.al/backend/uploads/1200x750/1601460109_1592896374DSC0524.jpg);
  ">
  <div class="w-fit lg:min-w-[35rem] min-w-[25rem] space-y-8 p-4 shadow-2xl bg-white">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-5 h-5 pl-1.5 cursor-pointer"
      (click)="goBack()">
      <path d="M19 12H6M12 5l-7 7 7 7" />
    </svg>
    <div>
      <div class="flex justify-center">
        <app-logo></app-logo>
      </div>
      <!-- <img class="mx-auto max-w-md h-12 w-auto" src="https://dev.al/backend/uploads/1650032017_1579259858devdevBooks_logo.svg" alt="Workflow"/> -->
      <h2 class="mt-6 text-center text-xl font-bold tracking-tight text-gray-900">
        {{ 'auth.change_password' | translate }}
      </h2>
    </div>
    <form class="mt-8 max-w-[35rem] flex flex-col space-y-2" [formGroup]="changePasswordForm" (submit)="onSubmit()">
      <app-password-input-component [passwordConfig]="oldPasswordConfig"></app-password-input-component>
      <app-password-input-component [passwordConfig]="newPasswordConfig"></app-password-input-component>
      <app-password-input-component [passwordConfig]="confirmNewPasswordConfig"></app-password-input-component>
      <p *ngIf="errorMessage" class="m-1 text-xs text-pink-700">{{ errorMessage }}</p>

      <div>
        <button
          type="button"
          class="group relative flex w-full justify-center rounded-md border border-transparent bg-deval_default-600 py-2 px-4 text-sm font-medium text-white hover:bg-deval_default-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:border-collapse disabled:border-deval_danger-500 disabled:bg-gray-500 disabled:text-gray-600"
          [disabled]="changePasswordForm.invalid"
          (click)="onSubmit()">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <svg
              class="h-5 w-5 text-white group-hover:text-deval_default-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd" />
            </svg>
          </span>
          {{ 'auth.send_request' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

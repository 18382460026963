import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthorizationState } from '../store/authorization.state';
import { isUserTokenPresent } from '../store/authorization.selectors';
import { authLogout } from '../store/authorization.actions';

export const LoggedInGuard: CanActivateFn = () => {
  return inject(LoggedInGuardService).canActivate();
};

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuardService {
  constructor(
    private store: Store<AuthorizationState>,
    private router: Router,
  ) {}

  private get isLoggedIn() {
    return this.store.select(isUserTokenPresent).pipe(
      take(1),
      tap(isLoggedIn => {
        if (!isLoggedIn) {
          // console.log('Not logged in');
          this.store.dispatch(authLogout());
          this.router.navigate(['/auth/login']);
        }
      }),
    );
  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedIn;
  }
}

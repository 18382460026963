<div *ngIf="show" class="w-[100vw] h-[100vh] absolute top-0 left-0 bg-gray-700 !opacity-50 !z-[99999999999999999]">
  <svg-icon
    src="assets/icons/devBooks_logo.svg"
    class="absolute top-[calc(50%-80px)] left-[47%]"
    routerLink="/dashboard"
    [svgClass]="'w-32 cursor-pointer'"></svg-icon>
  <svg-icon
    *ngIf="time > 10"
    src="assets/icons/outline/x_.svg"
    class="absolute top-[20px] left-[calc(100%-40px)]"
    routerLink="/dashboard"
    [svgClass]="'w-32 cursor-pointer bg-white'"
    (click)="hideLoading()"></svg-icon>

  <div class="lds-default" [ngStyle]="style">
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
  </div>
</div>

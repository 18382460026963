import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// noinspection ES6UnusedImports
import { ApiWrapperResponseOfString } from '../nswag.api';

export function nswagCatchOperator() {
  return function <ApiWrapperResponseOfString>(source: Observable<ApiWrapperResponseOfString>): Observable<ApiWrapperResponseOfString> {
    // eslint-disable-next-line no-undef
    return new Observable(subscriber => {
      source
        .pipe(
          catchError(error => {
            const language = JSON.parse(localStorage.getItem(`__root`)!)?.dashboard?.language ?? 'sq';

            try {
              if (error.response) return of(JSON.parse(error.response));
            } catch (error) {
              return of(
                language === 'en'
                  ? 'An unexpected server error occurred. Please try again later.'
                  : 'Ndodhi një gabim i papritur në server. Ju lutem provoni përsëri më vonë.',
              );
            }

            return of(
              new ApiWrapperResponseOfString({
                data: undefined,
                succeeded: false,
                message: error?.message?.includes('invalid_token')
                  ? language === 'en'
                    ? 'Your session has expired, please continue to Login.'
                    : 'Sessioni juaj ka skaduar, Ju lutem vazhdoni ne Login.'
                  : error?.message?.includes('An unexpected server error occurred.')
                    ? language === 'en'
                      ? 'Communication with the server failed. Please check your network connection and try again'
                      : 'Komunikimi me serverin nuk funksionoi. Ju lutem kontrolloni lidhjen me rrjetin dhe provoni perseri'
                    : error.message,
              }),
            );
          }),
        )
        .subscribe({
          next(value) {
            subscriber.next(value);
          },
          error(error) {
            subscriber.error(error);
          },
          complete() {
            subscriber.complete();
          },
        });
    });
  };
}

import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

export type InputPasswordConfig = {
  inputLabel: string;
  inputPlaceholder: string;
  formControl: FormControl;
  autocomplete: string;
  inputColors?: boolean;
};

@Component({
  selector: 'app-password-input-component',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent {
  @Input() passwordConfig!: InputPasswordConfig;
  showPassword: boolean = false;

  constructor() {}
}

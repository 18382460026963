import { IStateData } from 'src/app/shared/interfaces/state/state';
import moment from 'moment';
import { CostingCenterDto } from 'src/app/shared/nswag.api';

export const configurationsFeatureKey = 'configurations';

export const configurationsPersistKeys = [
  'costingCenter',
  'skipCache',
  'cachedUsers',
  'decimalPlaces',
  'cachedCostingCenters',
  'cachedCompanyStructures',
].map(value => `${configurationsFeatureKey}.${value}`);

export interface User {
  key: string;
  value: string;
}

export interface ConfigurationsState {
  skipCache: IStateData<boolean>;
  cachedCostingCenters: IStateData<CostingCenterDto>[];
}

export const initialConfigurationsState: ConfigurationsState = {
  skipCache: {
    data: false,
    lastUpdatedDate: moment(),
  },
  cachedCostingCenters: [],
};

import { createReducer, on } from '@ngrx/store';
import { initialInventoryState } from './inventory.state';
import moment from 'moment';
import {
  AccountingScheme,
  AssetCategory,
  AssetGroup,
  CacheGlAccount,
  CacheParentCategory,
  CacheUnits,
  CacheVats,
  ClearGlAccountsCache,
  ClearInventoryCache,
  ClearItemSchemesCache,
  ClearLocationCache,
  ClearUnitCache,
  ClearVatCache,
  ItemAccountingScheme,
  ItemCategory,
  ItemGoup,
} from './inventory.actions';

export const inventoryReducer = createReducer(
  initialInventoryState,

  on(ItemAccountingScheme.byNoSuccess, (state, { scheme }) => {
    return {
      ...state,
      cachedSchemes: [
        ...state.cachedSchemes.filter(c => c.data?.id !== scheme?.id),
        ...[
          {
            data: scheme,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(ItemGoup.byNoSuccess, (state, { itemGroup }) => {
    return {
      ...state,
      cachedGroups: [
        ...state.cachedGroups.filter(c => c.data?.id !== itemGroup?.id),
        ...[
          {
            data: itemGroup,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(ItemCategory.byNoSuccess, (state, { itemCategory }) => {
    return {
      ...state,
      cachedCategories: [
        ...state.cachedCategories.filter(c => c.data?.id !== itemCategory?.id),
        ...[
          {
            data: itemCategory,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(AssetCategory.byNoSuccess, (state, { category }) => {
    return {
      ...state,
      cachedAssetCategories: [
        ...state.cachedAssetCategories.filter(c => c.data !== category),
        ...[
          {
            data: category,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(AssetGroup.byNoSuccess, (state, { group }) => {
    return {
      ...state,
      cachedAssetsGroups: [
        ...state.cachedAssetsGroups.filter(c => c.data !== group),
        ...[
          {
            data: group,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(AccountingScheme.byNoSuccess, (state, { scheme }) => {
    return {
      ...state,
      cachedGlSchemes: [
        ...state.cachedGlSchemes.filter(c => c.data !== scheme),
        ...[
          {
            data: scheme,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(CacheGlAccount.byNoSuccess, (state, { account }) => {
    return {
      ...state,
      cachedGlAccs: [
        ...state.cachedGlAccs.filter(c => c.data?.id !== account?.id),
        ...[
          {
            data: account,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(CacheUnits.byNoSuccess, (state, { Unit }) => {
    return {
      ...state,
      cachedUnits: [
        ...state.cachedUnits.filter(c => c.data?.code !== Unit?.code),
        ...[
          {
            data: Unit,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(CacheVats.byNoSuccess, (state, { Vat }) => {
    return {
      ...state,
      cachedVats: [
        ...state.cachedVats.filter(c => c.data?.id !== Vat?.id),
        ...[
          {
            data: Vat,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(CacheParentCategory.byNoSuccess, (state, { parent }) => {
    return {
      ...state,
      cachedParentCategory: [
        ...state.cachedParentCategory.filter(c => c.data?.id !== parent?.id),
        ...[
          {
            data: parent,
            lastUpdatedDate: moment(),
          },
        ],
      ],
    };
  }),

  on(ClearGlAccountsCache, state => ({
    ...state,
    cachedGlAccs: [],
  })),

  on(ClearVatCache, state => ({
    ...state,
    cachedVats: [],
  })),

  on(ClearUnitCache, state => ({
    ...state,
    cachedUnits: [],
  })),

  on(ClearLocationCache, state => ({
    ...state,
    cachedLocations: [],
  })),

  on(ClearItemSchemesCache, state => ({
    ...state,
    cachedSchemes: [],
  })),
  on(ClearInventoryCache, state => ({
    ...state,
    cachedSchemes: [],
    cachedLocations: [],
    cachedUnits: [],
    cachedVats: [],
    cachedGlAccs: [],
  })),
);

import { createAction, props } from '@ngrx/store';
import { CompanyDto, CompanyOptionEnum, FunctionEnum, ModuleEnum, PermissionsEnum, UserContractDto } from '../../shared/nswag.api';

//#region <editor-fold desc="Login Step1">
export const authLoginStep1Request = createAction(
  '[Authorization] Login Step1 Request',
  props<{
    contract: string;
    username: string;
    password: string;
    isGoogleLogin: boolean;
  }>(),
);

export const authLoginStep1Success = createAction(
  '[Authorization] Login Step1 Success',
  props<{
    companyDtos: CompanyDto[] | undefined;
    loggedUserName: string;
  }>(),
);

export const authLoginStep1Failure = createAction(
  '[Authorization] Login Step1 Failure',
  props<{
    error: string;
    contract: string | undefined;
    username: string | undefined;
    password: string | undefined;
  }>(),
);
export const onRefreshTokenSuccess = createAction(
  '[Authorization On Refresh Token Success',
  props<{
    token: string | undefined;
    refreshToken: string | undefined;
  }>(),
);
export const onRefreshTokenRequest = createAction('[Authorization On Refresh Token Request');
//#endregion </editor-fold>

//#region <editor-fold desc="Login Step1 Social">

export const authLoginSocialStep1Request = createAction(
  '[Authorization] Login Step1 Request Social',
  props<{
    token: string;
  }>(),
);

export const authLoginSocialStep1Success = createAction(
  '[Authorization] Login Step1 Success Social',
  props<{
    userContracts: UserContractDto[];
  }>(),
);

export const authLoginSocialStep1Failure = createAction(
  '[Authorization] Login Step1 Failure Social',
  props<{
    error: string;
  }>(),
);

//#endregion </editor-fold>

//#region <editor-fold desc="Login Step2">
export const authLoginStep2Request = createAction(
  '[Authorization] Login Step2 Request',
  props<{
    company: CompanyDto;
    username: string;
    password: string;
    isGoogleLogin: boolean;
  }>(),
);
export const authLoginStep2Success = createAction(
  '[Authorization] Login Step2 Success',
  props<{
    company: CompanyDto;
    token: string;
    refreshToken: string;
  }>(),
);
export const authLoginStep2Failure = createAction(
  '[Authorization] Login Step2 Failure',
  props<{
    error: string;
  }>(),
);
//#endregion </editor-fold>

//#region Logout <editor-fold desc="Logout">

export const authLogout = createAction('[Authorization] Logout');

//#endregion </editor-fold>

//#region Logout <editor-fold desc="Decode Token">

export const loadModulesFunctionsPermissions = createAction(
  '[Authorization] Get Modules Functions Permissions',
  props<{
    skipCache?: boolean;
  }>(),
);

export const loadModulesFunctionsPermissionsSuccess = createAction(
  '[Authorization] Get Modules Functions Permissions Success',
  props<{
    modules: ModuleEnum[];
    functions: FunctionEnum[];
    permissions: PermissionsEnum[];
    configurations: { [key in keyof typeof CompanyOptionEnum]?: boolean };
    isLoadingModulesFunctionsAndPermissions: boolean;
  }>(),
);

export const loadModulesFunctionsPermissionsFailure = createAction(
  '[Authorization] Get Modules Functions Permissions Failure',
  props<{
    error: string;
  }>(),
);

export const onSwitchTenantRequest = createAction(
  '[Authorization]Change Tenant Request',
  props<{
    company: CompanyDto;
  }>(),
);
export const onSwitchTenantSuccess = createAction(
  '[Authorization]Change Tenant Success',
  props<{
    company: CompanyDto;
    token: string;
    refreshToken: string;
  }>(),
);
export const onSwitchTenantFailure = createAction(
  '[Authorization]Change Tenant Failure',
  props<{
    error: string;
  }>(),
);
export const onLoginPageRefresh = createAction('[Authorization]Login Refresh Page');
export const onRequestToken = createAction('[Authorization]Loading Page');
export const updateCompanies = createAction('[Authorization] Update Companies', props<{ companies: CompanyDto[] | undefined }>());
//#endregion </editor-fold>
